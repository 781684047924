export enum InputType {
  TEXT = "text",
  TEL = "tel",
  URL = "url",
  NUMBER = "number",
  EMAIL = "email",
  PASSWORD = "password",
}

export type InputProps = {
  label?: string;
  id?: string;
  placeholder?: string;
  required?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  type?: InputType;
  helperText?: string;
  icon?: React.ReactNode;
  error?: boolean;
  errorMessage?: string;
  value?: string | number | undefined;
  customClasses?: string;
};
