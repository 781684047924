import { useContext, useEffect, useState } from "react";
import { getMsalToken } from "./MSALApiClient";
import { AuthContext } from "@/context/AuthContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import { axiosFetch, axiosPost } from "./APIClient";
import { type AuthenticationResult } from "@azure/msal-browser";
import { env } from "@/env.mjs";
import { type CallbotRecord, type Dispositions } from "@/types/callbotRecord";
import { type DashboardStats } from "@/types/dashboard";
import {
  type UserExecutionByUser,
  type UseDistributeInvoicesRequest,
  type UseUnassignedInvoicesRequest,
} from "@/types/user";
import { type Task } from "@/types/task";
import { type Activity } from "@/types/callbotRecord";

export function useUnassignedTasks(
  from: string,
  to: string,
  suspense?: boolean,
  dateType?: string
) {
  const { msalInstance } = useContext(AuthContext);
  const getToken = async () => {
    return getMsalToken({
      msalInstance,
    });
  };

  const fetchUnassignedTasks = async () => {
    const token = await getToken();
    const params = {
      url: env.NEXT_PUBLIC_BACKEND_URL + "/api/executions/find",
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
      data: {
        status: "READY_TO_REVIEW",
        from: from,
        to: to,
        filterBy: dateType,
      },
    };
    return axiosPost<CallbotRecord[]>(params);
  };

  const result = useQuery({
    queryKey: ["unassignedTasks", from, to, dateType],
    queryFn: fetchUnassignedTasks,
    suspense: suspense,
  });
  return result;
}

export function useAssignedTasks(
  from: string,
  to: string,
  suspense?: boolean,
  executionStatus?: string,
  dateType?: string
) {
  const { msalInstance } = useContext(AuthContext);
  const getToken = async () => {
    return getMsalToken({
      msalInstance,
    });
  };

  const fetchAssignedTasks = async () => {
    const token = await getToken();
    const params = {
      url: env.NEXT_PUBLIC_BACKEND_URL + "/api/executions/find",
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
      data: {
        status: executionStatus || "READY_TO_REVIEW",
        from: from,
        to: to,
        filterBy: dateType,
      },
    };
    return axiosPost<CallbotRecord[]>(params);
  };

  const result = useQuery({
    queryKey: ["assignedTasks", from, to, executionStatus, dateType],
    queryFn: fetchAssignedTasks,
    suspense: suspense,
  });
  return result;
}

export function useMyTasks(
  from: string,
  to: string,
  suspense?: boolean,
  dateType?: string
) {
  const { msalInstance } = useContext(AuthContext);
  const getToken = async () => {
    return getMsalToken({
      msalInstance,
    });
  };

  const fetchMyTasks = async () => {
    const token = await getToken();
    const params = {
      url: env.NEXT_PUBLIC_BACKEND_URL + "/api/users/executions/mine",
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
      data: {
        status: "READY_TO_REVIEW",
        from: from,
        to: to,
        filterBy: dateType,
      },
    };
    return axiosPost<Task[]>(params);
  };

  const result = useQuery({
    queryKey: ["myTasks", from, to, dateType],
    queryFn: fetchMyTasks,
    suspense: suspense,
  });
  return result;
}

export function useAvailableUsers(
  from?: string,
  to?: string,
  suspense?: boolean
) {
  const { msalInstance } = useContext(AuthContext);
  const getToken = async () => {
    return getMsalToken({
      msalInstance,
    });
  };

  const fetchUsers = async () => {
    const token = await getToken();
    let queryString = "/api/users/available?";
    if (from) queryString += "&from=" + from;
    if (to) queryString += "&to=" + to;
    const params = {
      url: env.NEXT_PUBLIC_BACKEND_URL + queryString,
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
    };
    return axiosFetch<UserExecutionByUser[]>(params);
  };

  const result = useQuery({
    queryKey: ["useAvailableUsers", from, to],
    queryFn: fetchUsers,
    suspense: suspense,
  });

  return result;
}

export function useDashboardStats(from: string, to: string) {
  const { msalInstance } = useContext(AuthContext);
  const [token, setToken] = useState<AuthenticationResult | undefined>(
    undefined
  );
  useEffect(() => {
    const getToken = async () => {
      const response = (await getMsalToken({
        msalInstance,
      })) as AuthenticationResult;
      setToken(response);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getToken();
  }, [msalInstance]);

  const result = useQuery({
    queryKey: ["dashboardStats", from, to],
    queryFn: () => {
      const params = {
        url:
          env.NEXT_PUBLIC_BACKEND_URL +
          "/api/stats/dashboard?from=" +
          from +
          "&to=" +
          to +
          "&section=all",
        headers: {
          Authorization: "Bearer " + (token?.accessToken || ""),
        },
      };
      return axiosFetch<DashboardStats>(params);
    },
    enabled: !!token,
  });
  return result;
}

interface UseDistributeInvoicesProps {
  onError?: (error: unknown) => void;
  onSuccess?: () => void;
}

export function useDistributionMutation({
  onError,
  onSuccess,
}: UseDistributeInvoicesProps) {
  const { msalInstance } = useContext(AuthContext);
  const mutation = useMutation({
    mutationKey: ["distributeInvoices"],
    mutationFn: async (data: UseDistributeInvoicesRequest) => {
      const token = await getMsalToken({ msalInstance });
      const params = {
        url: env.NEXT_PUBLIC_BACKEND_URL + "/api/executions/distribute",
        headers: {
          Authorization: "Bearer " + (token?.accessToken || ""),
        },
        data,
      };
      return axiosPost(params);
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });
  return mutation;
}

export function useUnassignMutation({
  onError,
  onSuccess,
}: UseDistributeInvoicesProps) {
  const { msalInstance } = useContext(AuthContext);
  const mutation = useMutation({
    mutationKey: ["unassignInvoices"],
    mutationFn: async (data: UseUnassignedInvoicesRequest) => {
      const token = await getMsalToken({ msalInstance });
      const params = {
        url: env.NEXT_PUBLIC_BACKEND_URL + "/api/users/executions/unassign",
        headers: {
          Authorization: "Bearer " + (token?.accessToken || ""),
        },
        data,
      };
      return axiosPost(params);
    },
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });
  return mutation;
}

export interface UseLogActivityMutationProps {
  id: number;
  activities: Activity[];
}
export function useLogActivityMutation() {
  const { msalInstance } = useContext(AuthContext);
  const mutation = useMutation({
    mutationKey: ["newLogActivity"],
    mutationFn: async (data: UseLogActivityMutationProps) => {
      const token = await getMsalToken({ msalInstance });
      const params = {
        url: env.NEXT_PUBLIC_BACKEND_URL + "/api/users/executions/activities",
        headers: {
          Authorization: "Bearer " + (token?.accessToken || ""),
        },
        data,
      };
      return axiosPost(params);
    },
    onError: (error) => {
      throw error;
    },
  });
  return mutation;
}

export interface UseCompleteReviewMutationProps {
  record: {
    id: number;
    execution: {
      id: string;
    };
  };
  disposition: string;
}

export function useCompleteReviewMutation() {
  const { msalInstance } = useContext(AuthContext);
  const mutation = useMutation({
    mutationKey: ["completeReviewTask"],
    mutationFn: async (data: UseCompleteReviewMutationProps) => {
      const token = await getMsalToken({ msalInstance });
      const params = {
        url: env.NEXT_PUBLIC_BACKEND_URL + "/api/users/executions/save",
        headers: {
          Authorization: "Bearer " + (token?.accessToken || ""),
        },
        data,
      };
      return axiosPost(params);
    },
    onError: (error) => {
      throw error;
    },
  });
  return mutation;
}

export function useDispositions(suspense?: boolean) {
  const { msalInstance } = useContext(AuthContext);
  const getToken = async () => {
    return getMsalToken({
      msalInstance,
    });
  };

  const fetchDispositions = async () => {
    const token = await getToken();
    const params = {
      url: env.NEXT_PUBLIC_BACKEND_URL + "/api/dispositions/get_all",
      headers: {
        Authorization: "Bearer " + (token?.accessToken || ""),
      },
    };
    return axiosFetch<Dispositions[]>(params);
  };

  const result = useQuery({
    queryKey: ["useDispositions"],
    queryFn: fetchDispositions,
    suspense: suspense,
  });

  return result;
}
