export type FilterState = {
  dateRangeOption: string;
  dateRange: {
    from: Date;
    to: Date;
  };
  baselineRange: {
    from: number | string | undefined;
    to: number | string | undefined;
  };
  currentRange: {
    from: number | string | undefined;
    to: number | string | undefined;
  };
  assignee: string;
  status: string;
  activityCount: string;
};

export type DateRangeState = {
  dateRangeOption: string;
  dateRange: {
    from: Date;
    to: Date;
  };
};

export type ColumnFilter = {
  id: string;
  value: number[] | string[] | string;
};

export enum HeaderAlign {
  LEFT = "justify-start text-left",
  CENTER = "justify-center text-center",
  RIGHT = "justify-end text-right",
}

export const DATE_RANGE_OPTIONS = {
  TODAY: "today",
  THIS_WEEK: "this_week",
  THIS_MONTH: "this_month",
  CUSTOM: "custom",
};

export const DATE_TYPE = {
  LAST_UPDATED: "updated_at",
  COMPLETED_DATE: "created",
};
