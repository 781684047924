/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { type Dispatch, type SetStateAction, useState } from "react";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { type RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnFiltersState,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  type FilterFn,
  type SortingState,
  type Row,
  type RowData,
} from "@tanstack/react-table";

import classNames from "classnames";
import { FilterBar } from "@/components/shared/table/filter-bar";
import { type DateRangeSelection } from "@/types/dashboard";
import { type UserExecutionByUser } from "@/types/user";
import { type HeaderAlign } from "@/types/table";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
    activityCount: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

declare module "@tanstack/table-core" {
  interface ColumnMeta<TData extends RowData, TValue> {
    defaultColumn?: boolean;
    defaultSortingColumn?: boolean;
    headerAlign?: HeaderAlign;
  }
}

interface ReactTableProps<TData> {
  data: TData[];
  enablePinning?: boolean;
  columns: ColumnDef<TData>[];
  actionButtons?: (hasRowSelected: boolean) => React.JSX.Element;
  emptyComponent?: () => React.JSX.Element;
  defaultAllRowsSelected?: boolean;
  handleRowSelect?: (selectedRows: TData[]) => void;
  hasGlobalFilterBar?: boolean;
  showPageSizeSelector?: boolean;
  onClickRow?: (row: Row<TData>) => void;
  clickableColumnIds?: string[];
  onDateRangeChange?: (date: DateRangeSelection) => void;
  dateRangeSelection?: DateRangeSelection;
  showFilters?: boolean;
  showPagination?: boolean;
  showAllRowsSelector?: boolean;
  columnFilters?: ColumnFiltersState;
  setColumnFilters?: Dispatch<SetStateAction<ColumnFiltersState>>;
  pageSize?: number;
  showAssigneeFilter?: boolean;
  showStatusFilter?: boolean;
  isSmallTable?: boolean;
  users?: UserExecutionByUser[];
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const itemRank = rankItem(row.getValue(columnId), value, { threshold: 3 });

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

function SimpleTable<TData extends object>({
  data,
  enablePinning = false,
  columns,
  actionButtons,
  emptyComponent,
  defaultAllRowsSelected = false,
  handleRowSelect,
  showPageSizeSelector = false,
  hasGlobalFilterBar = true,
  onDateRangeChange,
  dateRangeSelection = {} as DateRangeSelection,
  showFilters = true,
  showPagination = true,
  showAllRowsSelector = true,
  columnFilters = [],
  setColumnFilters,
  pageSize = 10,
  showAssigneeFilter = false,
  showStatusFilter = false,
  isSmallTable = false,
  users = [],
}: ReactTableProps<TData>) {
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = React.useState({});
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = React.useState("");

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
      activityCount: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      columnVisibility,
      rowSelection,
      sorting,
    },
    autoResetPageIndex: false,
    enableRowSelection: true,
    enablePinning,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  React.useEffect(() => {
    setRowSelection({});
    table.toggleAllRowsSelected(false);
  }, [data.length]);

  React.useEffect(() => {
    const defaultSortingColumn = table
      .getAllColumns()
      .find(
        (column) =>
          column.columnDef?.meta &&
          column.columnDef.meta["defaultSortingColumn"]
      );

    if (defaultSortingColumn) {
      table.setSorting([{ id: defaultSortingColumn.id, desc: true }]);
    }
    if (!showPagination) {
      table.setPageSize(data.length);
    } else {
      table.setPageSize(pageSize);
    }
  }, [table]);

  const selectedRows = table.getState().rowSelection;

  React.useEffect(() => {
    const selectedRowData = table
      .getSelectedRowModel()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .flatRows.map((row) => row.original);
    if (handleRowSelect) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      handleRowSelect(selectedRowData);
    }
  }, [handleRowSelect, table, selectedRows]);

  React.useEffect(() => {
    if (defaultAllRowsSelected) {
      table.toggleAllRowsSelected(defaultAllRowsSelected);
    }
  }, [table, defaultAllRowsSelected]);

  const renderFilterBar = () => {
    if (!hasGlobalFilterBar) {
      return null;
    }
    return (
      <FilterBar
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        onDateRangeChange={onDateRangeChange}
        dateRangeSelection={dateRangeSelection}
        table={table}
        actionButtons={actionButtons}
        showFilters={showFilters}
        showAllRowsSelector={showAllRowsSelector}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        showAssigneeFilter={showAssigneeFilter}
        showStatusFilter={showStatusFilter}
        users={users}
      />
    );
  };

  const renderSelectedData = () => {
    if (hasGlobalFilterBar && Object.keys(rowSelection).length !== 0) {
      const countRowsSelected = Object.keys(rowSelection).length;
      const allRowSelectedText = table.getIsAllRowsSelected()
        ? "Deselect all"
        : `Select all the ${table.getFilteredRowModel().rows.length} records`;
      const rowsSelectedText = table.getIsAllRowsSelected()
        ? `All the ${countRowsSelected} records have been selected.`
        : countRowsSelected > 1
        ? `${countRowsSelected} records selected.`
        : `${countRowsSelected} record selected.`;
      return (
        <div className="flex h-14 flex-row items-center justify-center border-b border-gray-300 bg-gray-100 pl-5">
          <p className="text-sm text-gray-500">
            {rowsSelectedText}{" "}
            <a
              className="cursor-pointer text-brand-blue-600 underline"
              onClick={() =>
                table.toggleAllRowsSelected(!table.getIsAllRowsSelected())
              }
            >
              {allRowSelectedText}
            </a>
          </p>
        </div>
      );
    }
  };

  const getPageNumbers = (): number[] => {
    const totalVisiblePages = 5;
    const middlePage = Math.floor(totalVisiblePages / 2);
    const pages = [];

    if (table.getPageCount() <= totalVisiblePages) {
      for (let i = 0; i < table.getPageCount(); i++) {
        pages.push(i);
      }
    } else if (table.getState().pagination.pageIndex < middlePage) {
      for (let i = 0; i < totalVisiblePages; i++) {
        pages.push(i);
      }
    } else if (
      table.getState().pagination.pageIndex >=
      table.getPageCount() - middlePage
    ) {
      for (
        let i = table.getPageCount() - totalVisiblePages;
        i < table.getPageCount();
        i++
      ) {
        pages.push(i);
      }
    } else {
      for (
        let i = table.getState().pagination.pageIndex - middlePage;
        i <= table.getState().pagination.pageIndex + middlePage;
        i++
      ) {
        pages.push(i);
      }
    }

    return pages;
  };

  const renderPageSelector = () => {
    //TODO: review this component to display only 6 pages
    if (table.getPageCount() > 0) {
      return getPageNumbers().map((e) => {
        return (
          <button
            key={e}
            onClick={() => table.setPageIndex(e)}
            className={
              "h-6 w-6 " +
              (table.getState().pagination.pageIndex === e
                ? "text-brand-blue-600"
                : "text-gray-500")
            }
          >
            {e + 1}
          </button>
        );
      });
    }
    return null;
  };

  const firstItemNumberInPage =
    table.getState().pagination.pageIndex *
      table.getState().pagination.pageSize +
    1;

  const lastItemNumberInPage =
    (table.getState().pagination.pageIndex + 1) *
    table.getState().pagination.pageSize;
  return (
    <>
      <div
        className={`${
          isSmallTable ? "" : "mt-4"
        } flex flex-col overflow-hidden rounded-lg border border-gray-300`}
      >
        {renderFilterBar()}
        {renderSelectedData()}

        <div
          className={`${
            isSmallTable ? "bg-white" : "min-h-[530px]"
          } overflow-x-auto rounded-tl-none`}
          style={{ width: "100%" }}
        >
          <div className="inline-block min-w-full">
            <table className="min-w-full text-left text-sm font-light">
              <thead className="bg-gray-50 text-gray-500">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        scope="col"
                        className="px-6 py-4 font-medium"
                      >
                        <div
                          className={classNames(
                            "flex items-center",
                            header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            header.column.columnDef?.meta &&
                              header.column.columnDef?.meta["headerAlign"]
                              ? header.column.columnDef?.meta["headerAlign"]
                              : "",
                            header.column.getIsSorted()
                              ? "text-gray-900"
                              : "text-gray-500"
                          )}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <span>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </span>
                          {header.id !== "select" && (
                            <span>
                              {header.column.getIsSorted() &&
                                header.column.getIsSorted() === "asc" && (
                                  <ChevronUpIcon className="h-5 w-5" />
                                )}
                              {header.column.getIsSorted() &&
                                header.column.getIsSorted() === "desc" && (
                                  <ChevronDownIcon className="h-5 w-5" />
                                )}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="border-t border-gray-300 text-gray-900"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="whitespace-nowrap px-4 py-4">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
                {!table.getRowModel().rows.length && (
                  <tr>
                    <td colSpan={table.getHeaderGroups()[0]?.headers.length}>
                      {emptyComponent ? (
                        emptyComponent()
                      ) : (
                        <div className="flex min-h-[480px] min-w-full items-center justify-center">
                          <span>There are no records to display.</span>
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showPagination && !!table.getFilteredRowModel().rows.length && (
        <div
          className={`${
            isSmallTable ? "justify-between" : "mt-5"
          } flex flex-row items-center gap-2 border-t border-gray-300 pt-3`}
        >
          <span
            className={`${
              isSmallTable ? "basis-3/4" : "basis-1/4"
            } flex h-9 w-2 flex-row items-center justify-start text-sm font-normal xs:w-full`}
          >
            <div>Showing &nbsp;</div>
            <div className="text-gray-700">
              {" "}
              {firstItemNumberInPage} to{" "}
              {lastItemNumberInPage > table.getFilteredRowModel().rows.length
                ? table.getFilteredRowModel().rows.length
                : lastItemNumberInPage}{" "}
              of {table.getFilteredRowModel().rows.length} results
            </div>
          </span>
          <div
            className={`${
              isSmallTable
                ? "basis-1/4 justify-end"
                : "basis-2/4 justify-center"
            } flex flex-row`}
          >
            <button
              className="h-6 w-6 text-sm font-normal text-brand-blue-600 disabled:text-gray-200"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronLeftIcon />
            </button>
            {renderPageSelector()}
            <button
              className="h-6 w-6 text-sm font-normal text-brand-blue-600 disabled:text-gray-200"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <ChevronRightIcon />
            </button>
          </div>
          {showPageSizeSelector && (
            <div className="flex basis-1/4 flex-row items-center justify-end">
              <select
                className="h-10 rounded border border-gray-300 text-sm font-normal xl:w-[110px]"
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default SimpleTable;
