import React from "react";
import classNames from "classnames";

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  icon,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  icon?: React.ReactNode;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <div className="relative w-full">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        {icon}
      </div>
      <input
        {...props}
        value={value}
        className={classNames(props.className, { "!pl-10": icon })}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
}

export default DebouncedInput;
