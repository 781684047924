/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-empty-function */

import DebounceInput from "../../debounceInput";
import React from "react";
import { type DateRangeSelection } from "@/types/dashboard";
import IndeterminateCheckbox from "@/components/shared/indeterminateCheckbox";
import { ShowColumn } from "@/components/shared/table/filter-bar/showColumn";
import { ShowFilter } from "@/components/shared/table/filter-bar/showFilter";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { type ColumnFiltersState } from "@tanstack/react-table";
import { type UserExecutionByUser } from "@/types/user";

interface Props {
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters?: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
  onDateRangeChange?: (date: DateRangeSelection) => void;
  dateRangeSelection: DateRangeSelection;
  showFilters?: boolean;
  showAllRowsSelector?: boolean;
  filtersCount?: number;
  clearFilters?: () => void;
  table: any;
  actionButtons?: (hasRowSelected: boolean) => React.JSX.Element;
  showAssigneeFilter: boolean;
  showStatusFilter: boolean;
  users?: UserExecutionByUser[];
}

export function FilterBar({
  globalFilter,
  setGlobalFilter,
  columnFilters,
  setColumnFilters,
  onDateRangeChange,
  dateRangeSelection,
  showFilters = false,
  showAllRowsSelector = true,
  table,
  actionButtons,
  showAssigneeFilter,
  showStatusFilter,
  users = [],
}: Props): React.JSX.Element {
  const handleOnChangeRowSelection = () => {
    if (table.getIsAllPageRowsSelected()) {
      table.toggleAllRowsSelected(false);
    } else if (table.getIsSomePageRowsSelected()) {
      table.toggleAllPageRowsSelected(false);
    } else {
      table.toggleAllPageRowsSelected(true);
    }
  };

  return (
    <div className="flex flex-row justify-between gap-3 border-b border-gray-300 bg-gray-50 px-4 py-4">
      {showAllRowsSelector && (
        <div className="flex items-center justify-start text-left text-sm font-light text-gray-900">
          <label className="flex cursor-pointer items-center justify-center underline">
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate:
                  table.getIsSomePageRowsSelected() ||
                  table.getIsAllPageRowsSelected(),
                onChange: () => handleOnChangeRowSelection(),
              }}
            />
            <div className="whitespace-nowrap pl-2">
              {Object.keys(table.getState().rowSelection).length.toString() +
                " "}
              selected
            </div>
          </label>
        </div>
      )}
      {actionButtons
        ? actionButtons(!!Object.keys(table.getState().rowSelection).length)
        : null}
      <div className="align-center flex w-full flex-row justify-between gap-3">
        <DebounceInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="font-lg w-full appearance-none !rounded-md border-gray-300 !px-3 !py-2.5 text-gray-900 placeholder:text-gray-400"
          placeholder="Search..."
          icon={<MagnifyingGlassIcon className="h-5 text-gray-400" />}
          type="search"
        />
      </div>
      <div className="flex items-center justify-center gap-3">
        <div className="relative">
          <ShowColumn table={table} />
        </div>
        {showFilters && (
          <div className="relative">
            <ShowFilter
              dateRangeSelection={dateRangeSelection}
              onDateRangeChange={
                onDateRangeChange ? onDateRangeChange : () => {}
              }
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              showAssigneeFilter={showAssigneeFilter}
              showStatusFilter={showStatusFilter}
              users={users}
            />
          </div>
        )}
      </div>
    </div>
  );
}
