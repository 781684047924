/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import React, { useRef, useState } from "react";
import { EyeIcon } from "@heroicons/react/24/solid";
import { type Column, type RowData } from "@tanstack/react-table";
import { useClickOutside } from "@/helpers";
interface Props {
  table: any;
}

export function ShowColumn({ table }: Props): React.JSX.Element {
  const isSelectAllChecked = (state: any) => {
    return Object.keys(state).every((id) => state[id].checked);
  };

  const getInitialState = () =>
    table
      .getAllLeafColumns()
      .map((column: Column<RowData, any>) => {
        return {
          checked: column.getIsVisible(),
          column: column,
        };
      })
      .reduce(
        (
          acc: any,
          item: { checked: boolean; column: Column<RowData, any> }
        ) => ({
          ...acc,
          [item.column.id]: item,
        }),
        {}
      );

  const [state, setState] = useState(getInitialState());
  const [selectAll, setSelectAll] = useState(true);
  const [showColumns, setShowColumns] = useState(false);

  const columnRef = useRef<HTMLDivElement>(null);

  useClickOutside(columnRef, () => handleColumns());

  const handleColumns = () => {
    if (showColumns) {
      const newState = getInitialState();
      setState(newState);
      setSelectAll(isSelectAllChecked(newState));
    }
    setShowColumns(!showColumns);
  };

  const handleOnChangeSelection = (
    column: Column<RowData, any>,
    checked: boolean
  ) => {
    const newState = {
      ...state,
      [column.id]: {
        checked: checked,
        column: column,
      },
    };
    setSelectAll(isSelectAllChecked(newState));
    setState(newState);
  };

  const handleOnChangeAllSelection = (checked: boolean) => {
    setSelectAll(checked);
    const newState = Object.keys(state)
      .map((id) => {
        const item = state[id];
        return {
          ...item,
          checked: item.column.columnDef.meta?.defaultColumn ?? checked,
        };
      })
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.column.id]: item,
        }),
        {}
      );
    setState(newState);
  };

  const handleApplyColumnVisibility = () => {
    Object.keys(state).forEach((id) => {
      const item = state[id];
      item.column.toggleVisibility(item.checked);
    });
    setTimeout(() => handleColumns());
  };

  return (
    <>
      <button
        className="flex flex-row rounded-md border border-gray-300 bg-white px-4 py-3 text-sm text-gray-700 hover:bg-white/10"
        onClick={handleColumns}
      >
        <EyeIcon className="mr-2 h-5 w-5 cursor-pointer" />
        Column
      </button>
      {showColumns && (
        <div
          ref={columnRef}
          className="absolute right-0 top-[50px] z-20 flex h-auto w-[240px] flex-col justify-center rounded-md border border-gray-300 bg-white py-2 transition-transform ease-in-out"
        >
          <label className="border-b border-gray-200 px-6 py-3 text-sm text-gray-900">
            <input
              {...{
                type: "checkbox",
                checked: selectAll,
                onChange: (event) =>
                  handleOnChangeAllSelection(event.target.checked),
                className: "h-5 w-5 cursor-pointer rounded",
              }}
            />
            <span className="pl-2">Select All</span>
          </label>
          {table.getAllLeafColumns().map((column: Column<RowData>) => {
            return (
              <label
                key={column.id}
                className="px-6 py-3 text-sm text-gray-900"
              >
                <input
                  {...{
                    type: "checkbox",
                    checked: state[column.id]?.checked,
                    onChange: (event) =>
                      handleOnChangeSelection(column, event.target.checked),
                    className:
                      "group h-5 w-5 cursor-pointer rounded disabled:checked:bg-gray-400 disabled:cursor-default disabled:hover:checked:bg-gray-400",
                    disabled:
                      column.columnDef.meta &&
                      column.columnDef.meta["defaultColumn"],
                  }}
                />
                <span
                  className={`pl-2 ${
                    column.columnDef.meta &&
                    column.columnDef.meta["defaultColumn"]
                      ? "text-gray-600"
                      : "cursor-pointer"
                  }`}
                >
                  {column.columnDef.header as string}
                </span>
              </label>
            );
          })}
          <div className="flex items-center justify-end gap-3 border-t border-gray-200 px-3 px-6 pb-1 pt-3">
            <button
              className="rounded-md border border-gray-300 bg-white px-2 py-1 text-xs text-gray-700 hover:bg-white/10"
              onClick={() => {
                handleColumns();
              }}
            >
              Cancel
            </button>
            <button
              className="bg-brand-blue-600 rounded-md px-2 py-1 text-xs text-white hover:bg-blue-900"
              onClick={() => handleApplyColumnVisibility()}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </>
  );
}
